import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Element } from 'react-scroll';
import Slider from 'react-slick';

const PrevArrow = props => {
  const { className, onClick } = props;

  return (
    <button className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronCircleLeft} />
    </button>
  );
};

const NextArrow = props => {
  const { className, onClick } = props;

  return (
    <button className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronCircleRight} />
    </button>
  );
};

const About = () => {
  const indicators = ['H', 'A', 'R', 'T', 'S'];

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    appendDots: dots => (
      <div>
        <ul className="slick-slider-dots"> {dots} </ul>
      </div>
    ),
    customPaging: function (i) {
      return <a>{indicators[i]}</a>;
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section
      id="aboutContent"
      className="about-content  bg-no-repeat bg-left h-auto  lg:min-h-screen"
    >
      <Element name="about" className="h-full">
        <div className=" w-4/5 mx-auto h-full flex flex-col justify-center md:justify-between py-24">
          <h3 className="mix-color lg:mb-20">
            Let Us deliver <br className="block sm:hidden" />
            <span>What Do You Want</span>
            <br />
            <span>Beyond</span> Expectation
          </h3>
          <div className="harts-content">
            <Slider {...settings} className="slider">
              <div className="item">
                <h4>HARMONIZE</h4>
                <p>
                  Integrate and align wants and needs to achieve the expected
                  goals.
                </p>
              </div>
              <div className="item">
                <h4>ADAPTIVE</h4>
                <p>Always moving forward and keeping up with the times.</p>
              </div>
              <div className="item">
                <h4>RELIABLE</h4>
                <p>
                  work and work with the strength of a reliable team anytime and
                  anywhere.
                </p>
              </div>
              <div className="item">
                <h4>TENACIOUS</h4>
                <p>work to the fullest with passion and tenacity.</p>
              </div>
              <div className="item">
                <h4>SUSTAINABLE</h4>
                <p>
                  create a sustainable and sustainable strategy for long-term
                  success.
                </p>
              </div>
            </Slider>
          </div>
          <div className="info-content">
            <h5>PT. SIGMA INTAN MAHAKARYA</h5>
            <p>
              AKTA PENDIRIAN Tanggal 22 Maret 2016, no. 36, notaris Jusuf
              Patrianto Tjahjono
              <br />
              SK MENKUMHAM No. AHU-0015241.AH.01.01. TAHUN 2016, tanggal 23
              Maret 2016
              <br />
              LAMPIRAN SK MENKUMHAM No. AHU-0015241.AH.01.01 TAHUN 2016, tanggal
              23 Maret 2016
              <br />
              SIUP No. 503/2529.A/436.7.5/2016, tanggal 8 April 2016
              <br />
              TDP No. 503/3453.B/436.7.5/2016, tanggal 12 Mei 2016
            </p>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default About;
