import { graphql } from 'gatsby';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import About from '../components/section/home_about';
import Banner from '../components/section/home_banner';
import Benefits from '../components/section/home_benefit';
import News from '../components/section/home_news';
import Portofolio from '../components/section/home_portofolio';
import Services from '../components/section/home_services';
import SEO from '../components/SEO';
import Img from 'gatsby-image';

const Home = ({ data }) => {
  return (
    <>
      <style>
        {`.tl-edges {
          overflow-y: hidden !important;
        }`}
      </style>
      <SEO title="Welcome" />

      <Banner />
      <About />
      <Services />
      <Benefits />
      <Portofolio data={data.porto} />
      <News data={data.posts} />
    </>
  );
};

export const query = graphql`
  query {
    porto: allCockpitPortofolio(filter: { active: { value: { eq: true } } }) {
      nodes {
        cockpitBy
        active {
          value
        }
        cockpitCreated
        cockpitId
        title {
          value
        }
        id
        description {
          value
        }
        content {
          value {
            id
          }
        }
        slug {
          value
        }
        main_image {
          value {
            childImageSharp {
              fixed(width: 480, toFormat: WEBP) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }

    posts: allCockpitPosts(
      filter: { active: { value: { eq: true } } }
      sort: { fields: date___value, order: DESC }
    ) {
      nodes {
        cockpitId
        cockpitBy
        title {
          value
        }
        slug {
          value
        }
        categories {
          value {
            display
            _id
          }
        }
        date {
          value
        }
        description {
          value
        }
        main_image {
          value {
            childImageSharp {
              fixed(width: 480, toFormat: WEBP) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   {
//     profile: cockpitProfile {
//       content {
//         type
//         value
//       }
//     }
//     opening: cockpitOpening {
//       content {
//         type
//         value
//       }
//       director {
//         value
//       }
//       photo {
//         type
//         value {
//           childImageSharp {
//             fixed(width: 300, toFormat: WEBP) {
//               ...GatsbyImageSharpFixed_withWebp
//             }
//           }
//         }
//       }
//     }
//     vision: cockpitVision {
//       content {
//         type
//         value
//       }
//     }
//     mission: cockpitMission {
//       content {
//         type
//         value
//       }
//     }
//     numbers: allCockpitNumbers(filter: { display: { value: { eq: true } } }) {
//       edges {
//         node {
//           cockpitId
//           name {
//             value
//           }
//           photo {
//             value {
//               childImageSharp {
//                 fixed(toFormat: WEBP) {
//                   ...GatsbyImageSharpFixed_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     popup: cockpitHomepopup(display: { value: { eq: true } }) {
//       title {
//         value
//       }
//       content {
//         type
//         value
//       }
//     }
//     popup_image: cockpitHomepopupimage(display: { value: { eq: true } }) {
//       title {
//         value
//       }
//       photo {
//         value {
//           childImageSharp {
//             fluid(toFormat: WEBP) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//     sliders: allCockpitPrimarysliders(
//       filter: { display: { value: { eq: true } } }
//     ) {
//       edges {
//         node {
//           cockpitId
//           name {
//             value
//           }
//           link {
//             value
//           }
//           photo {
//             value {
//               childImageSharp {
//                 fluid(maxWidth: 1920, toFormat: WEBP) {
//                   ...GatsbyImageSharpFluid_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     files: allFile(filter: { name: { in: ["index-1", "index-2"] } }) {
//       edges {
//         node {
//           name
//           childImageSharp {
//             fluid(toFormat: WEBP, maxWidth: 1920) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export default Home;
