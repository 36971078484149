import React from 'react';
import ben1 from '../../images/ben-1.png';
import ben2 from '../../images/ben-2.png';
import ben3 from '../../images/ben-3.png';
import benImg from '../../images/benefit-img.png';

const Benefits = () => {
  return (
    <section className="benefit-content py-16 sm:py-0  h-auto  lg:min-h-screen">
      <div className="h-full flex flex-col justify-center px-6 sm:px-24">
        <div className="lg:grid grid-cols-2 gap-8 w-full  lg:w-4/5 mx-auto">
          <div className="left-content">
            <h4 className="title-section mx-0">Benefits</h4>
            <h3 className="subtitle-section text-left">
              Benefits that
              <span className="px-2 text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-lime-400">
                our partners
              </span>
              <br className="block lg:hidden" />
              will certainly get
            </h3>
            <p className="!max-w-none lg:!max-w-md">
              Entrust the solution to us all the vision, mission and goals that
              will be achieved to provide the best not only for you but for all.
              and get the convenience with us here.
            </p>
            <div className="md:flex lg:block flex-row md:space-x-4 lg:space-x-0">
              <div className="big-icon-2-cloumn">
                <div className="flex-none">
                  <img
                    src={ben1}
                    className="w-32 md:w-auto mx-auto lg:mx-left lg:w-full"
                  />
                </div>
                <div className="flex-1">
                  <h4>Fast Working Process</h4>
                  <p>
                    We are idea generators, goal seekers, challenge - thirsty
                    professionals team of unique internet project.
                  </p>
                </div>
              </div>
              <div className="big-icon-2-cloumn">
                <div className="flex-none">
                  <img
                    src={ben2}
                    className="w-32 md:w-auto mx-auto lg:mx-left lg:w-full"
                  />
                </div>
                <div className="flex-1">
                  <h4>System Security</h4>
                  <p>
                    We will also prioritize data security to avoid data leakage
                    to the public.
                  </p>
                </div>
              </div>
              <div className="big-icon-2-cloumn">
                <div className="flex-none">
                  <img
                    src={ben3}
                    className="w-32 md:w-auto mx-auto lg:mx-left lg:w-full"
                  />
                </div>
                <div className="flex-1">
                  <h4>Support Team</h4>
                  <p>
                    Our team will help and guide you to get the best service and
                    provide the best solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-content hidden lg:block h-full">
            <div className="img-wrapper">
              <img src={benImg} className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
