import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { ANICOLOR } from '../../theme/palette';
import { generateSlug } from '../../utils';

const Portofolio = ({ data }) => {
  const [post, setPost] = useState([]);

  useEffect(() => {
    if (data) {
      setPost(data.nodes.slice(0, 6));
    }
  }, [data]);

  return (
    <section className="portfolio-content py-16 h-auto">
      <Element name="portfolio" className="h-full">
        <div className="h-full flex flex-col justify-center px-6 sm:px-24">
          <h4 className="title-section">Portofolio</h4>
          <h3 className="subtitle-section">
            The result of
            <span className="px-3 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
              our work
            </span>
            with colleagues
          </h3>
          <div className="porto-wrapper">
            {post.map((d, i) => {
              const text = d.description.value;
              const maxLength = 100;

              let trimmedString = text.substr(0, maxLength);

              trimmedString = trimmedString.substr(
                0,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              );

              return (
                <div className="item">
                  <AniLink
                    paintDrip
                    hex={ANICOLOR}
                    to={`/portofolio/${generateSlug(d.title.value)}`}
                  >
                    <div className="img-wrapper">
                      <Image
                        fixed={d.main_image.value.childImageSharp.fixed}
                        className="img-porto !w-full !h-full"
                      />
                    </div>
                    <div className="px-3">
                      <h3 className="!text-xl">{d.title.value}</h3>
                      <p>{trimmedString}</p>
                      <span>See more</span>
                    </div>
                  </AniLink>
                </div>
              );
            })}
          </div>
          <div className="btn-wrapper text-center text-gray-600 mt-8">
            <AniLink
              paintDrip
              hex={ANICOLOR}
              to="/portofolio/"
              className="hover:text-gray-800"
            >
              Load More...
            </AniLink>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Portofolio;
