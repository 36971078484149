import React from 'react';
import { Element } from 'react-scroll';
import service1 from '../../images/service-1.png';
import service2 from '../../images/service-2.png';

const Services = () => {
  return (
    <section class="service-content h-auto py-20 lg:min-h-screen">
      <Element id="services" name="services" className="h-full pt-20">
        <div class="h-full flex flex-col justify-center px-6 sm:px-24">
          <h4 class="title-section">Our Services</h4>
          <h3 class="subtitle-section">
            See What
            <span class="px-3 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
              Our Offers
            </span>
            & What
            <span class="px-3 text-transparent bg-clip-text bg-gradient-to-br from-cyan-600 to-lime-400">
              We Provide
            </span>
          </h3>
          <div class="md:grid grid-cols-2 gap-20 mt-6 lg:mt-24 w-full  lg:w-4/5 mx-auto">
            <div class="service">
              <img src={service2} class="mx-auto md:mx-0" />
              <h3>Software Development</h3>
              <p>
                Our Team of developer offer you a custom software development,
                adapting to your unique Institution, to quickly transform your
                conventional data processing into a fully automated and
                integrated digital processing.
              </p>
              <p>
                We already have core software and experiences for Hospital /
                Healthcare Institution, University / Other Academic and General
                Office Administration Functionality, which can boost up our
                development time, resulting in short software delivery time for
                you.
              </p>
              <ul>
                <li>Web and Mobile Apps Development</li>
                <li>Corporate Digitalization</li>
                <li>Infrastructure</li>
                <li>Solution Provider</li>
              </ul>
            </div>
            <div class="service">
              <img src={service1} class="mx-auto md:mx-0" />
              <h3>Creative Lab</h3>
              <p>
                A solution to make a real impact and help in creative terms for
                branding your business with a team of professionals in their
                field.
              </p>
              <ul>
                <li>Marketing & Brand Strategy</li>
                <li>Content Creation</li>
                <li>Creative Visual Design</li>
                <li>Multimedia</li>
              </ul>
            </div>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Services;
