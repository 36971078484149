import { faCircle as farFaCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as fasFaCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import Slider from 'react-slick';
import { ANICOLOR } from '../../theme/palette';
import { generateSlug } from '../../utils';

const News = ({ data }) => {
  const [post, setPost] = useState([]);
  const [postLg, setPostLg] = useState(data ? data.nodes.slice(0, 1)[0] : {});

  const chunks = (a, size) =>
    Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
      a.slice(i * size, i * size + size)
    );

  useEffect(() => {
    if (data) {
      const arr = chunks(data.nodes.slice(1, 9), 2);

      setPost(arr);
    }
  }, [data]);

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    appendDots: dots => (
      <div>
        <ul className="slick-news-dots"> {dots} </ul>
      </div>
    ),
    customPaging: function (i) {
      return (
        <a>
          <FontAwesomeIcon icon={farFaCircle} className="dot-icon" />
          <FontAwesomeIcon icon={fasFaCircle} className="dot-active" />
        </a>
      );
    },
  };

  return (
    <section className="news-content h-auto lg:min-h-screen">
      <Element name="news" className="h-full">
        <div className="h-full flex flex-col justify-center px-6 sm:px-24">
          <h4 className="title-section">Blog</h4>
          <h3 className="subtitle-section">
            Get up-to-date
            <span className="px-3 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
              information
            </span>
          </h3>
          <div className="md:grid grid-cols-2 gap-8 mt-10 lg:mt-16 mb-5 w-full  lg:w-4/5 mx-auto">
            <div className="first-news w-[90%]">
              <div className="img-wrapper">
                <Image
                  fixed={postLg.main_image.value.childImageSharp.fixed}
                  className="img-porto !w-full rounded-xl drop-shadow-lg"
                />
              </div>
              <div className="cat-wrapper">
                <a
                  href={
                    postLg ? `/blog?cat=${postLg.categories.value._id}` : '#'
                  }
                >
                  {postLg ? postLg.categories.value.display : 'No Category'}
                </a>
                <span>{moment(postLg.date.value).format('MMM DD, YYYY')}</span>
              </div>
              <h3 className="text-xl font-bold text-gray-700 mt-4 mb-6">
                <AniLink
                  paintDrip
                  hex={ANICOLOR}
                  to={
                    postLg
                      ? `/blog/${
                          postLg.slug.value || generateSlug(postLg.title.value)
                        }-${postLg.cockpitId}`
                      : '#'
                  }
                >
                  {postLg ? postLg.title.value : 'No Title'}
                </AniLink>
              </h3>
              <p className="text-gray-600">
                {postLg ? postLg.description.value : 'No Description'}
              </p>
              <AniLink
                paintDrip
                hex={ANICOLOR}
                to={
                  postLg
                    ? `/blog/${
                        postLg.slug.value || generateSlug(postLg.title.value)
                      }-${postLg.cockpitId}`
                    : '#'
                }
                className="my-5 pb-1 inline-block border-b border-gray-400 text-gray-400 hover:text-gray-600 hover:border-gray-600"
              >
                Continue Reading
              </AniLink>
            </div>
            <div className="md:border-l border-gray-400 h-full mt-6 md:mt-0 md:px-10">
              <div className="flex flex-row justify-between ">
                <h3 className="text-xl font-semibold text-gray-600">
                  Latest Blog
                </h3>
                <AniLink
                  paintDrip
                  hex={ANICOLOR}
                  to="/blog"
                  className="text-gray-500 text-sm hover:text-gray-800"
                >
                  Load More...
                </AniLink>
              </div>
              <div className="latest-wrapper">
                <Slider {...settings} className="news-slider">
                  {post.map((x, i) => (
                    <div className="item">
                      {x.map(y => {
                        return (
                          <div className="news">
                            <div className="cat-wrapper">
                              <a
                                href={
                                  y
                                    ? `/blog?cat=${y.categories.value._id}`
                                    : '#'
                                }
                              >
                                {y ? y.categories.value.display : 'No Category'}
                              </a>
                              <span>
                                {moment(y.date.value).format('MMM DD, YYYY')}
                              </span>
                            </div>
                            <h3 className="text-xl font-bold text-gray-700 mt-4 mb-6">
                              <a
                                href={
                                  y
                                    ? `/blog/${
                                        y.slug.value ||
                                        generateSlug(y.title.value)
                                      }-${y.cockpitId}`
                                    : '#'
                                }
                              >
                                {y ? y.title.value : 'No Title'}
                              </a>
                            </h3>
                            <a
                              href={
                                y
                                  ? `/blog/${
                                      y.slug.value ||
                                      generateSlug(y.title.value)
                                    }-${y.cockpitId}`
                                  : '#'
                              }
                              className="my-5 pb-1 inline-block border-b border-gray-400 text-gray-400 hover:text-gray-600 hover:border-gray-600"
                            >
                              Continue Reading
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default News;
