import React from 'react';
import { Element } from 'react-scroll';
import Typed from 'typed.js';
// import bannerHome from '../../images/banner-right-image.png';
import imgmouse from '../../images/scroll-down-gif.gif';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Banner = () => {
  const el = React.useRef(null);
  const typed = React.useRef(null);

  const data = useStaticQuery(
    graphql`
      query Banner {
        banner: file(name: { eq: "hints-harts-web" }) {
          childImageSharp {
            fixed(toFormat: WEBP) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  );

  React.useEffect(() => {
    const options = {
      strings: [
        'Superb <span>Digital Ideas</span>',
        'Catchy <span>Name &amp; logo</span>',
        'Enterprise <span>Intelligent System</span>',
        'Emotional <span>Marketing Message</span>',
        'Corporate <span>Digitalization Program</span>',
        'Comprehensive <span>Social Media</span>',
        'Android &amp;<span> IOS Mobile Apps</span>',
        'Memorable <span>Branding Strategy</span>',
        'Custom <span>Software Development</span>',
        'Promotional &amp; <span>Merchandise Design</span>',
        'Life-easier <span>Software</span>',
        'Superb <span>Digital Ideas</span>',
      ],
      typeSpeed: 100,
      backSpeed: 30,
      startDelay: 2000,
      loop: true,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);

    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);

  return (
    <section className="top-content bg-gray-50 bg-[url('../images/baner-dec-left.png')] bg-no-repeat bg-left  h-screen">
      <Element name="home" className="h-full">
        <div className="grid grid-cols-12 h-full px-2  sm:px-16 2xl:px-32 ">
          <div className="left-content animate-fadeinleft col-span-full md:col-span-7 h-full flex flex-col justify-center">
            <h2 className="typewritter-area">We make Just For You</h2>
            <h3 className="sub-title">
              <em ref={el} className="not-italic">
                Memorable{' '}
                <span className="text-green-500">Branding Strategy</span>
              </em>
            </h3>
            <p className=" text-center md:text-left mt-10 md:mt-4 lg:mt-10 text-gray-700">
              Harts is a professional Marketing Content Creator and Integrated
              Software Developer based on{' '}
              <a
                rel="nofollow"
                href="https://g.page/hartsimagineering?share"
                target="_blank"
                className="text-sky-600 hover:text-sky-700"
              >
                Surabaya, Indonesia.
              </a>
              .
            </p>
            <div className="mx-auto md:mx-[inherit]">
              <img src={imgmouse} className="h-24 mt-8" />
            </div>
          </div>
          <div className="right-content  animate-fadeinright col-span-full md:col-span-5 h-full flex flex-col justify-center items-end">
            <a
              href="https://hartsimagineering.com/hints"
              target="_blank"
              className=""
            >
              {/* <img
                src={bannerHome}
                className=" w-[95%] md:w-5/6 hidden sm:block"
              /> */}
              <Img fixed={data.banner.childImageSharp.fixed} />
            </a>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Banner;
